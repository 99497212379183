<template>
  <div ref="main-page" class="company-org">
    <b-overlay :show="isloading" color="#38B22D" blur="5px" opacity="1.0" variant="transparent" rounded="sm" >
    <div class="top-options text-right">
      <!-- <b-button v-if="isCollapsed" class="p-50 mr-50 shadow" squared v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
        @click="expandAll()">
        <b-icon icon="chevron-double-down" />
      </b-button>
      <b-button v-else class="p-50 mr-50 shadow" squared v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
        @click="collapseAll()">
        <b-icon icon="chevron-double-up" />
      </b-button> -->
      <!-- <b-button squared class="btn-icon border-right-0" @click="zoomIn()" variant="outline-primary">
        <feather-icon icon="ZoomInIcon" width="16" />
      </b-button>
      <b-button squared class="btn-icon" @click="zoomOut()" variant="outline-primary">
        <feather-icon icon="ZoomOutIcon" width="16" />
      </b-button>
      <b-button squared class="ml-50 btn-icon shadow" variant="outline-primary">
        <span>Reset View</span>
      </b-button> -->
    </div>
  </hr>
    <b-card class="bg-transparent no-shadow">
      <!-- Vertical Company View Tree Design in Reporting Order -->
      <div class="companyview-body px-0" v-if="companyData && companyData['tree_type'] == 'Reporting Order'">
        <div class="companyview-tree mt-1 ml-1" id="company-tree" :style="{transform: 'scale(${zoomlevel})'}">
          <ul class="m-0 p-0 main-tree">
            <li id='company'>
              <b-card class="relative mb-0 company-dept">
                <div class="emp-pic">
                  <b-img :src="companyData['logo']" class="pro-img" fluid rounded="circle"
                    alt=""></b-img>
                </div>
                <div class="view-count d-flex align-items-center justify-content-between">
                  <b-badge variant="light"
                    class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon
                      person-circle icon="people" scale="1" class="mr-50"></b-icon>
                    <span>{{ companyData['employees_count'] }}</span></b-badge>
                  <b-badge variant="light"
                    class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon
                      person-circle icon="building" scale="1" class="mr-50"></b-icon>
                    <span>{{ companyData['departments_count'] }}</span></b-badge>
                </div>
                <div class="d-flex aign-items-center justify-content-center">
                  <!-- Drag Card -->
                  <!-- <div class="drag-action">
                      <b-icon icon="grip-vertical" width="17" height="17" class="align-middle text-primary rounded" v-b-tooltip.hover.v-default title="Move Tree"/>
                    </div> -->
                  <!-- Middle Content -->
                  <div class="tree-content">
                    <div class="mb-0 my-1">
                      <div class="goal-header text-center mt-2">
                        <h5 class="mb-0 emp-name font-weight-bolder">{{ companyData['name'] }}</h5>
                      </div>
                    </div>
                    <!-- <div class="expand-team">
                        <div class="expand-content">
                          <span class="team-count mx-25 small"><b-icon stacked icon="person"/>193</span>
                          <div style="border-left: 1px solid #dee1e4">
                            <span class="team-count mr-50 ml-25 small"><b-icon stacked icon="layers" class="mr-25"/>15</span>
                            <b-icon stacked icon="chevron-right" rotate="90" class="ml-25"></b-icon>
                          </div>
                        </div>
                      </div> -->
                  </div>
                </div>
                <!-- Employee Add Action -->
                <!-- <div class="tree-addaction">
                      <div class="delete-action">
                        <feather-icon icon="TrashIcon" size="14" class="mr-50 text-danger" />
                      </div> 
                      <b-dropdown class="blog-dropdown" variant="link" dropright id="dropdown-dropright" size="sm" toggle-class="text-decoration-none"
                        no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="PlusIcon" size="18" class="align-middle bg-primary text-white rounded" />
                        </template>
                        <b-dropdown-item v-if="employeeListOptions.length" class="btn-sm p-0 w-100" @click="node_type = 'employee'; parent_id = 'company';parent_name = companyData['name'];" v-b-toggle.addemp-popover >
                          <b-icon icon="person-plus" size="13" class="mr-50 text-primary" />
                          <span class="">Add Employee</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0 w-100" @click="node_type = 'department'; parent_id = 'company';parent_name = companyData['name'];" v-b-toggle.dept-popover>
                          <b-icon icon="building" size="13" class="mr-50 text-primary" />
                          <span class="">Add Department</span>
                        </b-dropdown-item>
                      </b-dropdown>
                  </div> -->
                <b-button id="root-node-arrow" class="expand-icon "><feather-icon icon="ChevronDownIcon" size="16"
                    class="text-primary font-weight-bolder m-0" @click="reportingListClicked('company')" /></b-button>
              </b-card>
              <nodes v-if="rootPeople.length" parent="company" :parentData="null" ref="children-company" />
            </li>
          </ul>
        </div>
      </div>
      <!-- Vertical Company View Tree Design in Custom Order -->
      <div class="companyview-body px-0" v-else >
        <div class="companyview-tree mt-1 ml-1" id="company-tree" :style="{transform: 'scale(${zoomlevel})'}">
          <ul class="m-0 p-0 main-tree">
            <li id='company'>
              <b-card class="relative mb-0 company-dept">
                <div class="emp-pic">
                  <b-img :src="companyData['logo']" class="pro-img" fluid rounded="circle"
                    alt="profile photo"></b-img>
                </div>
                <div class="view-count d-flex align-items-center justify-content-between">
                  <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="people" scale="1" class="mr-50"></b-icon> <span>{{employees_count}}</span></b-badge>
                  <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="building" scale="1" class="mr-50"></b-icon> <span>{{departments_count}}</span></b-badge>
                </div>
                <div class="d-flex aign-items-center justify-content-center">
                  <!-- Drag Card -->
                  <!-- <div class="drag-action">
                    <b-icon icon="grip-vertical" width="17" height="17" class="align-middle text-primary rounded" v-b-tooltip.hover.v-default title="Move Tree"/>
                  </div> -->
                  <!-- Middle Content -->
                  <div class="tree-content">
                    <div class="mb-0 my-1">
                      <div class="goal-header text-center mt-2">
                        <h5 class="mb-0 emp-name font-weight-bolder">{{ companyData['name'] }}</h5>
                      </div>
                    </div>
                    <!-- <div class="expand-team">
                      <div class="expand-content">
                        <span class="team-count mx-25 small"><b-icon stacked icon="person" />110</span>
                        <div style="border-left: 1px solid #dee1e4">
                          <span class="team-count mr-50 ml-25 small"><b-icon stacked icon="layers"
                              class="mr-25" />110</span>
                          <b-icon stacked icon="chevron-right" rotate="90" class="ml-25"></b-icon>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- Employee Add Action -->
                <div class="tree-addaction" v-if="$can('Zircly Admin')">
                      <div class="delete-action">
                        <!-- <feather-icon icon="TrashIcon" size="14" class="mr-50 text-danger" /> -->
                      </div> 
                      <b-dropdown class="blog-dropdown" variant="link" dropright id="dropdown-dropright" size="sm" toggle-class="text-decoration-none"
                        no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="PlusIcon" size="18" class="align-middle bg-primary text-white rounded" />
                        </template>
                        <b-dropdown-item v-if="employeeListOptions.length && $can('Zircly Admin')" class="btn-sm p-0 w-100" @click="node_type = 'employee'; parent_id = 'company';parent_name = companyData['name']; fetchEmployeeList();" v-b-toggle.addemp-popover >
                          <b-icon icon="person-plus" size="13" class="mr-50 text-primary" />
                          <span class="">Add Employee</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0 w-100" v-if="$can('Zircly Admin')" @click="node_type = 'department'; parent_id = 'company';parent_name = companyData['name'];" v-b-toggle.dept-popover>
                          <b-icon icon="building" size="13" class="mr-50 text-primary" />
                          <span class="">Add Department</span>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item class="btn-sm p-0 w-100">
                          <b-icon icon="diagram3" size="13" class="mr-50 text-primary" />
                          <span class="">Auto Generate</span>
                        </b-dropdown-item> -->
                      </b-dropdown>
                  </div>
                    <b-button id="root-node-arrow" class="expand-icon " v-if="subnodes && subnodes.length" ><feather-icon icon="ChevronDownIcon" size="16" class="text-primary font-weight-bolder m-0" @click="listClicked('company')" /></b-button>
              </b-card>
              <subnodes v-if="subnodes && subnodes.length && ( employees_count || departments_count )" parent="company" :parentData="null" :employeeList="employeeListOptions" ref="children-company" />
            </li>
          </ul>
        </div>
      </div>
      <!-- Add Employee Form Popup -->
      <b-sidebar
        id="addemp-popover"
        ref="addemp-popover"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="35%"
        title="Add New Employee"
        class="tree-emp"
        v-if="node_type && parent_name"
      >
        <template #default="{ hide }">
            <!-- Header -->
          <b-link class="text-primary floating-close" @click="hide">
          <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
        </b-link>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
          <h3 class="mb-0 font-weight-bold">Add New Employee</h3>
          <b-button size="sm" @click="addNode()" class="btn-primary">Save</b-button>
        </div>
          <!-- Footer -->
          <!-- BODY -->
          <b-card class="sidebar-pop">
            <b-row>
              <b-col>
                <label>Select Employees to place under "{{parent_name}}"</label>
                <v-select
                  :dir="
                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                  "
                  v-model="selectedEmployees"
                  :options="employeeListOptions"
                  class="w-100"
                  label="label"
                  :reduce="(val) => val.code"
                  placeholder="Select Employee"
                  input-id="select-person"
                  :clearable="false"
                  multiple
                />
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-sidebar>
      <!-- End Add Employee Form Popup -->
      <!-- Add Department Popup -->
      <b-sidebar
        id="dept-popover"
        ref="dept-popover"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="35%"
        title="Add New Department"
        class="tree-emp"
        v-if="node_type && parent_name"
      >
        <template #default="{ hide }">
              <!-- Header -->
        <b-link class="text-primary floating-close" @click="hide">
          <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
        </b-link>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
          <h3 class="mb-0 font-weight-bold">Add New Department</h3>
          <b-button size="sm" @click="addNode()" class="btn-primary">Save</b-button>
        </div>
          <!-- Footer -->
          <!-- BODY -->
          <b-card class="sidebar-pop">
            <b-row>
              <b-col sm="12">
                <label>Enter Department Name</label>
                <b-form-input
                  id="name"
                  v-model="department_name"
                  placeholder="Department Name"
                />
              </b-col>
              <b-col sm="12" class="mt-2 ml-auto color-pick">
                <b-form-group label="Node Color" label-for="node-color">
                  <colour-picker
                  v-model="color" :color="color"/>
                </b-form-group>
              </b-col>
              <!-- <b-col sm="12" v-if="department_name">
                <div>
                  <b-button :style="{backgroundColor:dynamicColor}" >{{department_name}} <b-badge>7</b-badge></b-button>
                </div>
              </b-col> -->
              <b-col sm="12">
                <label class="mt-2 mb-1">Preview Department</label>
                <div class="company-dept sub-department m-0">
                  <button class="btn dept-btn"
                  :style="{backgroundColor:color,color:'white'}">{{department_name}} <b-badge>01</b-badge></button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-sidebar>
      <!-- End Add Department Popup -->
      <!-- Edit Department Popup -->
      <b-sidebar
        id="edit-dept-popover"
        ref="edit-dept-popover"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="35%"
        title="Edit Department"
        class="tree-emp"
        v-if="node_type && parent_name && node_id"
      >
        <template #default="{ hide }">
              <!-- Header -->
        <b-link class="text-primary floating-close" @click="hide">
          <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
        </b-link>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header p-2 border-bottom sticky-top">
          <h3 class="mb-0 font-weight-bold">Edit Department</h3>
          <b-button size="sm" @click="updateNode()" class="btn-primary">Update</b-button>
        </div>
          <!-- Footer -->
          <!-- BODY -->
          <b-card class="sidebar-pop">
            <b-row>
              <b-col sm="12">
                <label>Select Department Name to place under"{{parent_name}}"</label>
                <b-form-input
                  id="name"
                  v-model="department_name"
                  placeholder="Department Name"
                />
              </b-col>
              <b-col sm="12" class="mt-2 ml-auto color-pick">
                <b-form-group label="Node Color" label-for="node-color">
                  <colour-picker
                  v-model="color" :color="color"/>
                </b-form-group>
              </b-col>
              <!-- <b-col sm="12" v-if="department_name">
                <div>
                  <b-button :style="{backgroundColor:dynamicColor}" >{{department_name}} <b-badge>7</b-badge></b-button>
                </div>
              </b-col> -->
              <b-col sm="12">
                <label class="mt-2 mb-1">Preview Department</label>
                <div>
                  <button class="btn dept-btn"  :style="{backgroundColor:color,color:'white'}"> <span >{{department_name}}</span> <b-badge :style="{backgroundColor:color,color:'white'}"><span>99</span></b-badge></button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-sidebar>
      <!-- End Edit Department Popup -->
      <!-- Employee Info Popup -->
      <b-sidebar id="Profile-View" ref="Profile-View" bg-variant="white" shadow backdrop no-header right width="35%"
        title="Employee Profile">
        <template>
          <!-- Header -->
          <div class="
                d-flex
                justify-content-between
                align-items-center
                content-sidebar-header
                px-2
                py-2
                border-bottom
                sticky-top
              ">
            <h3 class="mb-0 font-weight-bold">Employee Profile</h3>
            <div>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                @click="hideProfile()">
                <feather-icon size="20" icon="XCircleIcon" />
              </b-button>
            </div>
          </div>
          <!-- BODY -->

          <b-card :img-src="require('@/assets/images/banner/banner-1.jpg')" img-alt="Profile Cover Photo" img-top
            class="card-profile sidebar-pop">
            <div class="profile-image-wrapper ">
              <div class="profile-image p-0">
                <b-avatar size="114" variant="light" :src="require('@/assets/images/logo/dckap-logo.jpg')" />
              </div>
            </div>
            <h3>User Name</h3>
            <h6 class="text-muted">
              username@dckap.com
            </h6>
            <b-badge class="profile-badge" variant="light-primary">
              Quality Analyst
            </b-badge>
            <hr class="mb-2">

            <!-- follower projects rank -->
            <div>
              <b>Employee Details</b>
              <b-list-group class="mt-1">
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Bio :
                    </b-col>

                    <b-col cols="8">

                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Date of Join :
                    </b-col>

                    <b-col cols="8">
                      2021-10-07
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Employee ID :
                    </b-col>

                    <b-col cols="8">
                      DCKAPIN1000
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Reporting Manager :
                    </b-col>

                    <b-col cols="8">
                      Mohan Natarajan
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Zircly Role :
                    </b-col>

                    <b-col cols="8">
                      Employee
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Division :
                    </b-col>

                    <b-col cols="8">
                      Klizer
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Department :
                    </b-col>

                    <b-col cols="8">
                      Klizer
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4">
                      Shift :
                    </b-col>

                    <b-col cols="8">
                      India Day Shift (9:30 am - 6:30 pm)
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-card>
        </template>
      </b-sidebar>
      <!-- Employee Info Popup -->
    </b-card>
    <template #overlay>
      <div class="text-center">
        <dot-loader :loading="true" color="#38B22D" size="200px"></dot-loader>
      </div>
    </template>
    </b-overlay>
  </div>
</template>
<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BCarousel,
  BCarouselSlide,
  BOverlay
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import QuillInput from "@/components/QuillInput.vue";
import store from "@/store";
import $ from "jquery";
import Vue from "vue";
import { bus } from "@/main.js";
import axios from "@axios";
import VueCookies from "vue-cookies";
import Nodes from "./nodes.vue";
import Subnodes from "./subnodes.vue";
import ColourPicker from "@/components/CustomColorPicker.vue";
import DotLoader from 'vue-spinner/src/DotLoader.vue'
Vue.use(VueCookies);

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    vSelect,
    BAvatarGroup,
    BEmbed,
    QuillInput,
    // Navbar Components
    BCarousel,
    BCarouselSlide,
    Nodes,
    ColourPicker,
    Subnodes,
    BOverlay,
    DotLoader
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      zoomlevel: 1,
      userData: this.$cookies.get('userData'),
      selectedEmployees: [],
      employeeListOptions: [],
      parent_id: null,
      node_id:null,
      parent_name: null,
      department_name: null,
      node_type : null,
      companyData: [],
      rootPeople: [],
      subnodes:[],
      color:'#38B22D',
      msg:'',
      employees_count:0,
      departments_count:0,
      isCollapsed:true,
      isloading: false,
    }
  },
  created() {
    this.getTreeType();
    
    this.fetchEmployeeList();
  },
  mounted() {
    $('.companyview-tree ul').hide();
    $('.companyview-tree>ul').show();
    $('.companyview-tree ul.active').hide();
  },
  methods: {
    zoomIn() {
      this.zoomlevel += 0.1;
    },
    zoomOut() {
      if (this.zoomlevel > 0.1) {
        this.zoomLevel -=0.1;
      }
    },
    resetVariables(){
        this.selectedEmployees =  [];
        this.parent_id =  null;
        this.parent_name =  null;
        this.department_name =  null;
        this.node_type  =  null;
        this.node_id = null;
        this.color = '#38B22D';
        this.showDeptAddSidebar = false;
        this.showDeptEditSidebar = false;
        this.showEmpAddSidebar = false;
        this.showEmpInfoSidebar = false;
      },
    getTreeType(){
      this.isloading = true;
      axios
        .get("companyTree/type")
        .then((response) => {
          if (response.data.success) {
            this.companyData = response.data.data;
            if (this.companyData.tree_type == 'Reporting Order') {
              this.getRootPeople();
            }else{
              this.getTreeSubNode('company');
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch Company Type",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Company Type',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    fetchEmployeeList() {
      axios
        .get("list/tree-available-employees")
        .then((response) => {
          if (response.data.success) {
            this.employeeListOptions = response.data.data;
          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch available employee list for tree ",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch available employee list for tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            
          }
        });
    },
    getRootPeople() {
      this.isloading = true;
      axios
        .get("companyTree/rootPeople")
        .then((response) => {
          if (response.data.success) {
            this.rootPeople = response.data.data;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch Company Tree",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Company Tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      this.isloading = false;
    },
    addNode(){
      if(this.parent_id && (this.selectedEmployees.length || this.department_name)){
        var data = {
          parent_id : this.parent_id,
          people : this.selectedEmployees,
          name : this.department_name,
          node_color : this.color,
          node_type : this.node_type
        }
       this.isloading = true;
        axios
          .post("companyTree/addNode",data)
          .then((response) => {
            if (response.data.success) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Node added to the Chart",
              //     icon: "CheckIcon",
              //     variant: "success",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Node added to the Char',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              this.$refs['dept-popover'].hide();
              this.$refs['addemp-popover'].hide();
              this.fetchEmployeeList();
              this.$refs['children-' + this.parent_id].getSubNodesPeople();
                this.resetVariables();
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to fetch Company Tree",
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Company Tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
       this.isloading = false;      
      }else{
        if(!this.parent_id){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Parent Node is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Parent Node is not selected properly',
                icon: 'EditIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
        }
        if(!this.selectedEmployees.length && this.node_type == 'employee'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Employee is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee is not selected properly',
                icon: 'EditIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
        }
        if(!this.department_name && this.node_type == 'department'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Name is required",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Department Name is required',
                icon: 'EditIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    updateNode(){
      if(this.node_id && this.department_name){
        var data = {
          node_id : this.node_id,
          people : this.selectedEmployees,
          name : this.department_name,
          node_color : this.color,
          node_type : this.node_type
        }
        this.isloading = true;

        axios
          .post("companyTree/updateNode",data)
          .then((response) => {
            if (response.data.success) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Node Updated",
              //     icon: "CheckIcon",
              //     variant: "success",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Node Updated',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              this.$refs['edit-dept-popover'].hide();
              this.fetchEmployeeList();
              this.$refs['children-' + this.node_id].getSubNodesPeople();
                this.resetVariables();
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to fetch Company Tree",
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: response.data.message,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Company Tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
        this.isloading = false;
      }else{
        if(!this.node_id){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Node is not selected properly",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Node is not selected properly`,
              icon:'EditIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
        }
        if(!this.department_name && this.node_type == 'department'){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Department Name is required",
          //     icon: "EditIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Department Name is required`,
              icon:'EditIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
        }
      }
    },
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async reportingListClicked(id){
      this.isloading = true;
      // await this.sleep(2000);
      var nodeId = '#'+id;
        $("#root-node-arrow").toggleClass("activenode");
        $(nodeId).addClass("open-tree"); 
        $(nodeId).siblings('li').removeClass('open-tree');
        $(nodeId).siblings('li').find('nodes').hide();
        var children = $(nodeId).find('>div>ul');
        if (children.is(":visible")) {
          children.hide('fast').removeClass('active');
        }
        else {
          children.show('fast').addClass('active');
          this.$refs['children-'+id].getSubNodesPeople();
        }
      this.isloading = false;      
    },
    async listClicked(id) {
      this.isloading = true;
      // await this.sleep(2000);
      var nodeId = '#' + id;
      $("#root-node-arrow").toggleClass("activenode");
      $(nodeId).addClass("open-tree");
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('nodes').hide();
      var children = $(nodeId).find('>div>ul');
      if (children.is(":visible")) {
        children.hide('fast').removeClass('active');
      }
      else {
        children.show('fast').addClass('active');
        try{
          this.$refs['children-' + id].getSubNodesPeople();
        }
        catch{
          this.$refs['children-' + id][0].getSubNodesPeople();
        }
      }
      this.isloading = false;
    },
    getTreeSubNode(parent_id='company'){
      this.isloading = true;
      axios
        .get("companyTree/treeSubNode/"+parent_id)
        .then((response) => {
          if (response.data.success) {
            this.subnodes = response.data.subnodes;
            this.employees_count = response.data.employees_count;
            this.departments_count = response.data.departments_count;
            var nodeId = '#company';
            $("#root-node-arrow").toggleClass("activenode");
            $(nodeId).addClass("open-tree");
            $(nodeId).siblings('li').removeClass('open-tree');
            $(nodeId).siblings('li').find('nodes').hide();
            var children = $(nodeId).find('>ul');
            if (children.is(":visible")) {
              children.hide('fast').removeClass('active');
            }
            else {
              children.show('fast').addClass('active');
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch Company Tree",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Company Tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      this.isloading = false;    
    },
    
  },
  computed: {
    dynamicColor() {
      return this.color;
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.content-wrapper.h-100 {
  position: relative;
}
</style>